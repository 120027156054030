<template>
<div>
     <navbar class="mt-3"/>
     <ul class="container list-group mt-3">
            <li class="list-group-item text-left  border rounded mb-2" 
                v-for="(iphone,index) in smartphones " :key="iphone">
                <div class="row">
                    <div class="col border p-3">
                        {{iphone.name}}
                       {{iphoneprice}}
                       
                    </div>
                   
                     <div class="col justify-content-right border p-3">
                       
                            <select class="form-control mb-3 " v-model="selectedzustand" @change="onChangezustand()">                    
                                   <option v-for="(zustand) in iphone.specifications[index].zustand" :key="zustand"  :value="zustand">{{zustand}} </option>
                          </select>
                           <select class="form-control mb-3 " v-model="selectedmemory" @change="onChangememory()">                    
                                   <option v-for="(memory) in iphone.specifications[index].memories" :key="memory"  :value="memory">{{memory}} </option>
                          </select>
                           <select class="form-control mb-3 " v-model="selectedcolor" @change="onChangecolor()">                    
                                   <option v-for="(color) in iphone.specifications[index].colors" :key="color"  :value="color">{{color}} </option>
                          </select>
                         
                        
                         
                         
                      
                    </div>
                  
                </div>
            </li>
    </ul>
</div>
    
</template>
<script>
import navbar from "@/components/navbar.vue";
import db from "@/components/firebaseInit.js";
export default {
    name: "smartphones",
    components:{
     navbar
    },
    data(){
        return{
            smartphones:[],
            selectedzustand:null,
            selectedcolor:null,
            selectedmemory:null,
            selectedprice:null
           

          
           


        }
    },
    methods:{
       getprice(){
              if(this.selectedmemory=="32" && this.selectedzustand=="neu")
         {
              console.log("this.smartphones.name;"+this.smartphones.name);
             return this.smartphones.specifications[0].price;
         }
         else if(this.selectedmemory=="32" && this.selectedzustand=="gebraucht")
         {
               console.log("this.smartphones[0].price;"+this.smartphones[0].price);
             return this.smartphones.specifications[0].price-50;
         }
         else
         return 0;

       },
       onChangezustand(){
            console.log("this.smartphones.name;"+this.smartphones[0].name);
          console.log("this.selectedzustand"+this.selectedzustand);
          this.getprice();
       },
        onChangecolor(){
          console.log("this.selectedzustand"+this.selectedcolor);
           this.getprice();
       },
       onChangememory(){
          console.log("this.memory"+this.selectedmemory);
           this.getprice();
       }

    },
    computed:{
        iphoneprice: function(){
             if(this.selectedmemory=="32" && this.selectedzustand=="neu")
         {
             
             return this.smartphones[0].specifications[0].price;
         }
         else if(this.selectedmemory=="32" && this.selectedzustand=="gebraucht")
         {
              
              return this.smartphones[0].specifications[0].price-50;
         }
          else if(this.selectedmemory=="64" && this.selectedzustand=="gebraucht")
         {
              
              return this.smartphones[0].specifications[0].price+50;
         }
          else if(this.selectedmemory=="64" && this.selectedzustand=="neu")
         {
              
              return this.smartphones[0].specifications[0].price+100;
         }
         else
         return 0;

        }
   
    },
    
    
    created(){
        db.collection("iphone").onSnapshot((query)=>{
                this.smartphones=[];
                  query.forEach((doc)=>{
                      
                      this.smartphones.push({
                          Key: doc.id,
                          name: doc.data().iphone_name,
                        
                         specifications: doc.data().specifications,
                       
                       
                         
                      })

                  })
              })
           
            .catch(error => console.log(error)); 
            console.log("this.smartphones.price32"+this.smartphones.specifications[0].price);  
          
    }
    
    }
    
</script>