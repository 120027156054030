<template>
    <div class="">
        <computers  v-if="this.$route.params.category=='computers'"/>
         <monitors  v-if="this.$route.params.category=='monitors'"/>
  
     
    </div>
</template>
<script>

import computers from '../components/computers.vue';
import monitors from '../components/monitors.vue';


export default {
    name:"products",
    data(){
        return{
                 
        }       
    },
    components:{computers,monitors},
}
</script>