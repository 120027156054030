<template>
         <nav class="navbar navbar-expand-lg navbar-light " id="nav">
  
  <button class="navbar-toggler " type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon">
      </span>
  
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
         <router-link  to="/" class="nav-link" >Home <span class="sr-only">(current)</span></router-link> 
      </li>
      <li class="nav-item">
        <router-link  :to="{name:'products',params:{'category':'computers'}}" class="nav-link" >Computers</router-link> 
      </li>
      <li class="nav-item">
        <router-link  :to="{name:'products',params:{'category':'monitors'}}" class="nav-link" >Monitore</router-link> 
      </li>
    
      <li class="nav-item">
         <router-link to="/service"  class="nav-link"> Service</router-link>         
      </li>
    </ul>
  
  </div>
</nav>

</template>
<script>
import db from "../components/firebaseInit"
import firebase from "firebase"
import "firebase/auth"
export default {
    name: "navbar",
    data(){
      return{
        cart:[],
         scrollPosition: null,
         hpcomputer:null,
         dellcomputer:null,   
      }
    },
    created(){
      db.collection("products").onSnapshot(query=>{ 
        this.hpcomputer=[];
        this.dellcomputer=[];                         
               query.forEach(element => {
                 if(element.data().product_hersteller=='HP Computer')
                    this.hpcomputer.push(element.data())               
              
                  else if(element.data().product_hersteller=='Dell Computer')
                    this.dellcomputer.push(element.data())  
              })
                  })

    },

    methods:{
       updateScroll() {
       this.scrollPosition = window.scrollY;
        const nav=document.getElementById("nav");
       if(this.scrollPosition>100){
          
       nav.classList.add("fixed-top");
       
           nav.classList.add("navbar-expand-lg");
       
      
      
       }
       else{
         nav.classList.remove("fixed-top");
       
        
      
       }
       }
       ,
      getwarenkorp(){
        return this.$store.state.warenkorp.length;
      },
      ausloggen(){
        firebase.auth().signOut();
         this.$store.dispatch('setUser',null)
      }
    },
      mounted() {
    window.addEventListener('scroll', this.updateScroll);
} 
    
}
</script>
<style lang="scss" >
:root {
  --main-bg-color: #1d4684;
  
}

#nav {   
  padding: 0;
  background-color: var(--main-bg-color);
 
  
 router-link {
   
    display: block;
    width: 100%;
    height: 100%;
    padding: 15px 15px;
    color: black;
     text-decoration: none;
    &.router-link-exact-active {
      color: #ffffff;
      background-color: black;
    }
    &:hover{
      color: #ffffff;
      background-color: gray;

    }
  }
}

.navbar-nav{
   
  li{
     padding: 10px;
     &:hover{
          color:var(--main-bg-color);
    background-color: white;
     }
  }
}

@keyframes borderBottom {
  0% {
    width: 0%;
    left: 0;
  }
 
  100% {   
    width: 100%;
  }
}
  



.navbar-expand-lg {
    flex-flow: column nowrap !important;
 
}
.dropdown-menu{
  margin: -0.875rem 0 0 !important;
 
}

 @media (max-width:992px)
{
 .navbar{
 align-items: start !important;   
 
}
}



</style>