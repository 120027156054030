<template>
  <div class="details text-center mt-4">
     <navbar  />
     <div class="container border-bottom">
     
      <div class="row my-5"> 
      <div class="col">
        <div class="row ">
            <div class="product-image border ">
                 <img :src="require(`@/assets/${this.privewimg}.jpg`)" class="img-fluid   h-100" alt="">  
            </div>
                   <div class="row px-5" >
                         <div class=" details col p-0 border" v-for="item in this.details" :key="item">
                             <img  :src="require(`@/assets/${item}.jpg`)"  @click="displayimg(item)" class=" img-fluid  m-0 p-0" alt="">  
                         </div>
                   </div>
          </div> 
          </div>
          <div class="col  px-3 my-3 ">
           
                <h1 class=" text-grey m-3 d-block border-bottom text-left ">{{this.product.name}}</h1>
           
            <div class="row ">
              <select class="form-control " v-model="selected" @change="onChange()">                    
                      <option v-for="(memory,index) in product.memorys" :key="memory"  :value="index">{{memory}}MB </option>
              </select>
              
              <select class="form-control  my-3" v-model="selectedcolor" @change="onChange()" v-if="selected!== null">                     
                      <option v-for="(color) in product.colors" :key="color" >{{color}} </option>
              </select>
           
           
              <div class="d-block text-left col-6 m-0  text-center "><strong><h1 class="display-3">{{product.prise[selected]}}€</h1></strong></div>
              <div class="col-6">
                <div class="row px-2 ">
                        <button class="col-12 btn btn-primary my-1 ">Sofort kaufen</button>
                        <button class="col-12 btn btn-info my-1 " >in den Warenkorb</button>
                        
                        <button class="col-12 btn btn-white border-primary my-1" >beobachten</button>
                 </div>
                </div>
          </div>
           </div>
            </div>
             </div>
              </div>
   
</template>
<script>

import navbar from "@/components/navbar.vue";

export default {
    name: "detail",
      data(){
   return{
      urls: ['apple','iphone11','iphone12'],
      msg: null,
      selected: 0,
      selectedcolor: null,
       details: this.product.details,
      
      privewimg: this.product.maimimg,
      pro:null,
     
   }},
   props:['product'],
   
    components:{
         navbar,      
    },
      
    methods:{
      displayimg(src)
      {
       this.privewimg=src
        
      },
      onChange(){
         console.log(this.selected);
          console.log(this.selectedcolor);
          if(this.selectedcolor==='black')
             this.details=this.product.images.black;
          else if(this.selectedcolor==='selber')
            this.details=this.product.images.selber;
          else{
            this.details=this.product.details;
          }

        
       
      },
   
       
       
    },
    watch(){
     
      
    }
    
  
,
created()
 {

   
  
 }
}
</script>
<style scoped>

.details{
  opacity: 0.5;
}
.details:hover{
  border: 2px solid black;
  padding: 5px;
  margin: 1px;
  border-radius: 5px ;
  overflow: hidden;
  opacity: 1;
}

</style>