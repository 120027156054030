<template>
<div class="container ">
    <h1 class="">Admin Page</h1>
    <section class="category bg-light">
        <h1 class="text-left"> Add Category</h1>
        <div class="form-group">
            <div class="row justify-content-around  overflow-hidden  ">
                 <h4 class=" col-1   ">  Category: </h4>
            <input type="text " class=" form-control col-9" v-model="category">
            <button class="btn btn-primary   col-1" :disabled="!category"
            @click.prevent="addnewcategory()">+</button>     

            </div>

            
         <ul class="list-group mt-3">
             <li class="list-group-item mb-1 text-left" v-for="category in categorylist" :key="category">
                 {{category.category}}
                 <i class="fas fa-trash float-right" 
                 @click.prevent="deletecategory(category.doc_id)"></i>
                 </li>
         </ul>
        </div>
    </section>
    <section class="product overflow-hidden mt-5 p-3 bg-light ">
        <h1 class="text-left">
            Add Product
        </h1>
        <select class="form-control mb-3" v-model="productcategory">
             <option >-- Select Category</option>
            <option v-for="category in categorylist" :key="category" :value="category.category">{{category.category}}</option>
           
        </select>
        <div class="computer " v-if="productcategory=='Computer'">
            <div class="form-inline mb-2  border-bottom">
                <select class="form-control m-2 "  v-model="hersteller">
                <option >HP Computer</option>
                <option >Dell Computer</option>
                <option >Lenovo Computer</option>
                <option > acer Computer</option>
                <option >--Sonstige--</option>
                </select>
                  <select class="form-control m-2 "  v-model="type">
                <option value="Businiss Computer">Businiss Computer</option>
                <option value="Gaming Computer"> Gaming Computer</option>
                <option value="All-In-One Computer">All-In-One Computer</option>

                </select>
                <select class="form-control m-2 "  v-model="prozessor">
                <option value="3i Prozessor">3i  Prozessor</option>
                <option value="5i Prozessor">5i  Prozessor</option>
                <option value="7i Prozessor">7i  Prozessor</option>
                <option value="AMD Prozessor"> AMD  Prozessor</option>
                </select>

                 <select class="form-control m-2 "  v-model="arbeitsspeicher">
                <option value="4GB RAM">4GB RAM</option>
                <option value="8GB RAM">8GB RAM</option>
                <option value="16GB RAM">16GB RAM</option>

                </select>
                 <select class="form-control m-2 "  v-model="color">
                <option >schwarz</option>
                <option >weis</option>
                <option >andere Farbe</option>

                </select> 
                  
                        
                 <select class="form-control m-1 "  v-model="festplatte">
                <option value="4GB RAM">120  GB </option>
                <option value="8GB RAM">160  GB </option>
                <option value="16GB RAM">250  GB </option>
                <option value="16GB RAM">500  GB </option>
                <option value="16GB RAM">1  TB</option>

                </select>
               
                                  
                <select class="form-control m-1 "  v-model="festplattetechnologie">
                <option value="4GB RAM">HDD </option>
                <option value="8GB RAM">SSD </option>


                </select>
                 <select class="form-control m-2 float-left"  v-model="zustand">
                <option >neu</option>
                <option >Gebraucht A-Ware</option>
                <option >Gebraucht B-Ware</option>

                </select>
                   <input type="number" class="form-control form-control-sm mt-2" v-model="amount" placeholder="amount..">
                <input type="number" class="form-control form-control-sm mt-2" v-model="price" placeholder="Price in €...">
                  <div class="custom-file mt-2 text-left">
                <input type="file" class="custom-file-input" id="customFile" lang="de-DE" 
                  accept="image/png, image/jpeg"
                   @change="onFileChange">
                   <label class="custom-file-label" for="customFile" >Bilder hinzufügen</label>
                </div>
                    <ul class="imageslist">
                        <li v-for="image in imageslist" :key="image" class="">
                                            <img :src="require(`../assets/${image}`)" 
                                        style="width:150px ;height:150px"
                                        class="img-thumbnail image-fluid " >
                        </li>
                  </ul>
              
            </div>
             <div class="form-inline mb-2 border-bottom">
                  <input type="text" class="form-control m-2" id="" v-model="Model" placeholder="Modellbezeichnung...">
                  <input type="text" class="form-control m-2" id="" v-model="software" placeholder="Betriebssystem...">

              </div>
                <div class="form-inline mb-2 border-bottom"> 
                <input type="text" class="form-control m-2" id="" v-model="prosessorhersteller" placeholder="prosessorhersteller...">                       
                <input type="text" class="form-control m-2" id="" v-model="kerne" placeholder="CPU-Kerne...">
               <input type="text" class="form-control m-2" id="" v-model="arbeitsspeichertechnologie" placeholder="RAM Technologie...">
                <input type="text" class="form-control m-2" id="" v-model="grafikhersteller" placeholder="Grafik Hersteller...">
                <input type="text" class="form-control m-2" id="" v-model="grfikkarte" placeholder="grfikkarte...">
               </div>
            <div class="form-inline mb-2 border-bottom"> 
                <input type="text" class="form-control  m-2" id="" v-model="Netztwerk" placeholder="Netztwerk...">
                <input type="text" class="form-control  m-2"  id="" v-model="sound" placeholder="sound...">
                <input type="text" class="form-control  m-2"  id="" v-model="Laufwerk" placeholder="Laufwerk...">                    
             
              </div>
            
                 <select class="form-control m-2 border-bottom"  v-model="Ausstatungs">
                <option >--Sonstige Ausstatungs--</option>
                <option value="DVD Laufwerk">DVD Laufwerk</option>
                <option value="SSD">SSD</option>
                <option value="HDMI">HDMI</option>
                <option value="Grafikkarte">Grafikkarte</option>

                </select>
            
        <textarea class="form-control mt-2" v-model="productdescription" placeholder="Product description...."></textarea>     
        <button class="btn btn-primary float-left mt-2" @click.prevent="addproduct()">Add</button>
        </div>
        <div v-if="productcategory=='Monitor'" class="monitors">
               <select class="form-control m-2 "  v-model="hersteller">
                        <option >HP </option>
                        <option >Dell </option>
                        <option >SAMSUNG </option>
                        <option > acer </option>
                        <option >--Sonstige--</option>
                </select>
                <input type="text" class="form-control m-2" id="" v-model="Model" placeholder="Modellbezeichnung...">
                  <select class="form-control m-2 "  v-model="size">
                        <option value="15">15"</option>
                        <option value="17"> 17"</option>
                        <option value="20">20"</option>
                        <option value="24">24"</option>
                        <option value="27">27"</option>

                </select>
                    <select class="form-control m-2 "  v-model="color">
                    <option >schwarz</option>
                    <option >weis</option>
                    <option >andere Farbe</option>

                </select> 
                   <select class="form-control m-2 float-left"  v-model="zustand">
                <option >neu</option>
                <option >Gebraucht A-Ware</option>
                <option >Gebraucht B-Ware</option>

                </select>
                  <input type="number" class="form-control form-control-sm mt-2" v-model="amount" placeholder="amount..">
                    <input type="number" class="form-control form-control-sm mt-2" v-model="price" placeholder="Price in €...">
                   <div class="custom-file mt-2 text-left">
                <input type="file" class="custom-file-input" id="customFile" lang="de-DE" 
                  accept="image/png, image/jpeg"
                   @change="onFileChange">
                   <label class="custom-file-label" for="customFile" >Bilder hinzufügen</label>
                </div>
                    <ul class="imageslist">
                        <li v-for="image in imageslist" :key="image" class="">
                                            <img :src="require(`../assets/${image}`)" 
                                        style="width:150px ;height:150px"
                                        class="img-thumbnail image-fluid " >
                        </li>
                  </ul>
            
        <textarea class="form-control mt-2" v-model="productdescription" placeholder="Product description...."></textarea>     
        <button class="btn btn-primary float-left mt-2" @click.prevent="addmonitor()">Add</button>
        </div>         
        
    </section>
    <section class="angebote">
        <div class="container">
            <h1>Angebote Bieten</h1>
            <h1>Select Product</h1>
            <div class="form-inline">
                <select v-model="reduse" class="form-control mb-3">
               <option v-for="product in productslist" :key="product" :value="product.doc_id">{{product.product.product_model}}</option>
            </select>
            <input type="number" class="form-control mb-3" min="0" max="100" step="1" v-model="procent">%
            <button class="btn btn-primary" @click.prevent="makeingebot()">Reduzieren</button>
          
            

            </div>
            
        </div>
    </section>

    
</div>
    
</template>

<script>
import db from "../components/firebaseInit"
export default {
    name: "service",
    data(){
        return{
                  productslist:null,
                  reduse:null,
                  procent:null,
                    category:null,
                    categorylist:null,
                    productcategory:null,
                    type:null,
                    prozessor:null,
                    hersteller:null,
                    Model:null,
                    software:null,
                    prosessorhersteller:null,
                    kerne:null,
                    arbeitsspeichertechnologie:null,
                    grafikhersteller:null,
                    grfikkarte:null,
                    festplatte:null,
                    festplattetechnologie:null,
                    Netztwerk:null,
                    sound:null,
                    Laufwerk:null,
                    Ausstatungs:null,
                    color:null,
                    zustand:null,
                    amount:null,
                    price:null,
                    productdescription:null,
                    imageslist:null,
                    /////
                    size:null
            
        }
    },
    created(){
        db.collection("categories").onSnapshot(query=>{
            this.categorylist=[];
            query.forEach(doc=>{
                this.categorylist.push({
                    doc_id:doc.id,
                    category:doc.data().category
                    });
            })
        });
         db.collection("products").onSnapshot(query=>{
            this.productslist=[];
            query.forEach(doc=>{
                this.productslist.push({
                    doc_id:doc.id,
                    product:doc.data()
                    });
            })
        });

    },
    methods:{
        addnewcategory(){
            if(this.category.trim()!="")
            {
                 db.collection("categories").add({
                category:this.category
            }).then(()=>{
                this.category="";
            }).catch(err=>{console.log(err)})

            }
            else{
                alert(" Cann not  Add empty Category");
                this.category=null;
            }
           
        },
        deletecategory(id){
            db.collection("categories").doc(id).delete()
        },
        addproduct(){
            alert("test")
          db.collection("products").add({

              product_category:this.productcategory,
              product_hersteller:this.hersteller,
              produc_type:this.type,
              product_prozessor:this.prozessor,
              product_model:this.Model,
               product_prosessorhersteller:this.prosessorhersteller,
               product_kerne:this.kerne,
              product_arbeitsspeichertechnologie:this.arbeitsspeichertechnologie,
              product_grafikkarte:this.grfikkarte,
              product_grafikkartehersteller:this.grafikhersteller,
              product_Netztwerk:this.Netztwerk,
              product_sound:this.sound,
              product_Laufwerk:this.Laufwerk,
              product_festplatte:this.festplatte,
              product_festplattetechnologie:this.festplattetechnologie,
              product_arbeitsspeicher:this.arbeitsspeicher,
              product_ausstatung:this.Ausstatungs,
              product_color:this.color,
              product_zustand:this.zustand,
              product_amount:this.amount,
              product_price:this.price,
              product_imageslist:this.imageslist,
              product_description: this.productdescription,
          }).then(()=>{

                  /*   this.Model=null;
                    this.prozessor=null;
                    this.hersteller=null;
                    this.arbeitsspeicher=null;
                    this.Ausstatungs=null;
                    this.type=null;
                    this.zustand=null;
                    this.color=null;
                    this.price=null;
                    this.amount=null;
                    this.imageslist=null;
                    this.productdescription=null;
                    this.prosessorhersteller=null;
                    this.kerne=null;
                    this.arbeitsspeichertechnologie=null;
                    this.sound=null;
                    this.Laufwerk=null;
                    this.festplatte=null;
                    this.festplattetechnologie=null;
                    this.Netztwerk=null; */
          }).catch(err=>{console.log(err)});
        },
     addmonitor(){
           db.collection("products").add({
                    product_color:this.color,
                    product_category:this.productcategory,
                    product_hersteller:this.hersteller,
                     product_model:this.Model,
                    product_zustand:this.zustand,
                    product_amount:this.amount,
                    product_price:this.price,
                    product_imageslist:this.imageslist,
                    product_description: this.productdescription,
                    product_size:this.size,

           }).then(()=>{alert("added")}).catch(err=>{console.loge(err)
           })

     }   ,
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      console.log(files[0].name);//object contain: name and .....
      var reader = new FileReader();
      if (!files.length)
        return;
      reader.readAsDataURL(files[0]);//The Blob or File from which to read.
       reader.onload = () => {
       // this.image = e.target.result;
        if(this.imageslist!=null)
            this.imageslist.push(files[0].name);
        else{
            this.imageslist=[];
            this.imageslist.push(files[0].name);
        }
    
      //  console.log(e.target.result);// path aber in base64 base64
      };
    
    },
    makeingebot(){
        db.collection("products").doc(this.reduse).get().then(product=>{
            product.redused=this.prozent
            db.collection("products").doc(this.reduse).update({
                product_reduse:this.procent
            }).then(()=>alert("product"+this.reduse+"successfully redused")).catch()
            
            
        }).catcth()

    },
    
    },
  
}
</script>
<style lang="scss" scoped>
.imageslist{
    margin: 0;
    padding: 0;
    list-style-type: none;
    li{
        float: left;
        margin: 5px;
    }
}
.category{
    padding-top: 50px;
}
.col-1{
    padding: 0 !important;
    margin: 0 !important;
}
.col-9{
    padding: 0 !important;
    margin: 0 !important;
}
.col-2{
    padding: 0 !important;
    margin: 0 !important;
}
.form-group{
 
    padding-top: 50px;

}

</style>