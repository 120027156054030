<template>
  <div id="app">
    <searchlogo />
    <navbar /> 
  
    <router-view />
  </div>
</template>
<script>
import navbar from "@/components/navbar.vue";
import searchlogo from "./components/searchlogo.vue";
export default{
      components:{
                 navbar,   
                 searchlogo,
                
              

    },

}

</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


</style>
