<template>
    <div class="footer ">
     <div class="container  ">
         <div class="row ">
             <div class="col-xl-4 col-md-6 col-sm-6 text-left">
                 <div>
                     <h1>
                         Media Verkauf
                     </h1>
                 </div>
             </div>
             <div class="col-xl-4 col-md-6">
                 <ul class="text-left">
                     <li>Ahmad Al Asaf</li>
                     <li>HauptStr. 33</li>
                     <li> 87787 Wolfertschwenden</li>
                 </ul>
             </div>
             <div class="col-xl-4 col-md-12 d-flex">
                 <i class="fas fa-phone-alt mr-3"></i>
                 <p>015175614666</p>
             </div>
             <div class="col-xl-4 col-md-12">
                
             </div>
         </div>
    
    </div>
    <div class=" copyright ">
      <div class="container text-left">
         <p class=" text-center d-block mb-0"><span class="">&copy;</span><span> Copy right : Ahmad Al Asaf </span> </p>
 
      </div>
        
    </div>

    </div>
</template>
<script>
export default {
    name: "footercomponent",
}
</script>
<style lang="scss" scoped>
.footer{
    padding-top: 40px;
    
    color: whitesmoke;
    background-color: rgb(73, 72, 72);
    position: relative;
   
}

.container .row div:nth-child(2) ul{
    padding: 0;
    margin: 0;
    list-style-type: none;
    li{
        padding: 0;
        margin: 0;
    }
}
.footer .copyright{
   
    color: white;
   
    width: 100%;
    margin-top: 40px;
    background-color: rgb(39, 38, 38);
    p{
      padding: 10px 10px;
    }
}
</style>