<template>
    <div class="">
  
      <section class="main bg-gray">
          <div class="container  bg-light">
             <div class="row overflow_hidden">
                 
                 <div class="col-xl-12   text-left p-0">
                          <nav class="navbar p-0  navbar-light bg-light">                             
                                <button class="navbar-toggler" type="button" data-toggle="collapse" 
                                data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" 
                                aria-expanded="false" aria-label="Toggle navigation">
                                    <i class="fas fa-filter text-primary"></i>
                                    <span class="navbar-toggler-icon "></span>
                                </button>
                                <div class="collapse navbar-collapse" id="navbarNavDropdown">                                  
                                          <div class="  row" id="navbarNavDropdown" aria-labelledby="navbarDropdownMenuLink">
                                              <div class="col-lg-3 col-md-4 col-sm-4 col-5">
                                                <div class=" dropdown-item form-group form-check">
                                                                <input type="checkbox" name="" class="form-check-input" id="HPid"
                                                                value="HP Computer" v-model="filterarray">
                                                                <label class="form-check-label" for="HPid">HP({{productscounter().hp}})</label>
                                                </div>
                                                <div class="  dropdown-item form-group form-check">
                                                        <input type="checkbox" name=""  class="form-check-input" id="Dellid" 
                                                        value="Dell Computer" v-model="filterarray">
                                                        <label class="form-check-label" for="Dellid">Dell({{productscounter().dell}})</label>
                                                </div>
                                                        <div class="  dropdown-item form-group form-check">
                                                        <input type="checkbox" name=""  class="form-check-input" id="acerid" 
                                                        value="acer" v-model="filterarray">
                                                        <label class="form-check-label" for="acerid">acer({{productscounter().acer}})</label>
                                                </div>
                                                <div class=" dropdown-item form-group form-check">
                                                        <input type="checkbox" name=""  class="form-check-input" id="Lenovoid" 
                                                        value="Lenovo" v-model="filterarray">
                                                        <label class="form-check-label" for="Lenovoid">Lenovo({{productscounter().lenovo}})</label>
                                                </div>

                                              </div>
                                              <div class="col-lg-3 col-md-4 col-sm-4 col-5">
                                                   <div class="dropdown-item form-group form-check">
                                                            <input type="checkbox" v-model="filterarray" value="3i Prozessor" 
                                                            class="form-check-input" id="ProzessorCheck1">
                                                            <label class="form-check-label" for="ProzessorCheck1">i3 ({{productscounter().i3}})</label>
                                                    </div>
                                                    <div class="dropdown-item form-group form-check">
                                                            <input type="checkbox" v-model="filterarray" value="5i Prozessor" 
                                                            class="form-check-input" id="ProzessorCheck2">
                                                            <label class="form-check-label" for="ProzessorCheck2">i5 ({{productscounter().i5}})</label>
                                                    </div>
                                                    <div class="dropdown-item form-group form-check">
                                                            <input type="checkbox" v-model="filterarray" value="7i Prozessor" 
                                                            class="form-check-input" id="ProzessorCheck3">
                                                            <label class="form-check-label" for="ProzessorCheck3">i7 ({{productscounter().i7}}) </label>
                                                    </div>

                                              </div>
                                       
                                       
                                          </div>
                                 </div>
                          </nav>
                         <div class="row ">                             
                              <div class="card col-xl-12 p-0 border-0  mb-3" v-for="prod in secondfilter" :key="prod"
                               style="max-width: 100%">
                                <div class="row no-gutters">
                                    <div class="col-md-4">
                                   <router-link :to="{name:'details',params:{'productId':prod.doc_id}}">
                                            <img :src="require(`../assets/${prod.product.product_imageslist[1]}`)" 
                                            class="card-img border computer-card-image"
                                            :id="`productImage${prod.doc_id}`"                                           
                                                >
                                   </router-link>
                                    </div>
                                    <div class="col-md-8">
                                    <div class="card-body p-0">
                                        <h3 class="card-title  model">{{prod.product.product_model}}</h3>
                                        <p class="card-text ml-2">{{prod.product.product_zustand}}.</p>
                                        <h5 class="price  text-left ml-2"
                                        v-bind:style="prod.product.product_reduse ?'text-decoration: line-through red double ;' : 'border: none;'" 
                                        >EUR {{prod.product.product_price}} </h5>

                                         <h5 class="card-text text-success ml-2" 
                                         v-if="prod.product.product_reduse">EUR {{prod.product.product_price-(prod.product.product_price*prod.product.product_reduse/100)}} 
                                         </h5>

                                        <p class="card-text ml-2"><small class="text-muted">Last {{prod.product.product_amount}} Stuck/Stücke im Lager</small></p>
                                    </div>
                                    </div>
                                </div>
                              </div>
                         </div>
                        
                 </div>
             </div>
          </div>
      </section>  
      <Footercomponent />
    </div>
</template>
<script>
import Footercomponent from '../components/footercomponent.vue';
import db from "../components/firebaseInit";

export default {
    name:"monitors",
    data(){
        return{
             price:0,
             productslist:null,
             filterarray:[],           
        }       
    },
    components:{Footercomponent,},
    created(){
     
         db.collection("products").onSnapshot((query)=>{
            this.productslist=[];
            query.forEach((doc)=>{
                if(doc.data().product_category=='Monitor')
                {
                     this.productslist.push({
                doc_id:doc.id,
                product:doc.data()

                })

                }
               
                  
            })
           


        }).catch(err=>{console.log(err)})
           
          
        
    },
    methods:{
          herstellercounter(){
            let hpcounter=0
            let dellcounter=0
            let acercounter=0
            let lenovocounter=0
             this.productslist.forEach(product=>{
                 if(product.product_hersteller=="HP ")
                 {
                     hpcounter++;
                 }
                 else if(product.product_hersteller=="Dell "){
                        dellcounter++;
                 }
                  else if(product.product_hersteller=="acer "){
                        acercounter++;
                 }
                  else if(product.product_hersteller=="Lenovo "){
                        lenovocounter++;
                 }
             })
          return {
              hp:hpcounter,
              dell:dellcounter,
              acer:acercounter,
              lenovo:lenovocounter
          }
        },
   
     
          productscounter(){
                let productscounter={}
                    productscounter.dell=0
                    productscounter.hp=0
                    productscounter.acer=0
                    productscounter.lenovo=0
                 
             this.productslist.forEach((product)=>{
             Object.values(product.product).forEach(val =>{
                    
                    if(val=='HP '){productscounter.hp=productscounter.hp+1}
                    else if(val=='Dell '){productscounter.dell=productscounter.dell+1}
                    else if(val=='acer '){productscounter.acer=productscounter.acer+1}
                    else if(val=='Lenovo '){productscounter.lenovo=productscounter.lenovo+1}
                                                   
             })
             });
             return productscounter

         },
       
       
    },
   
    computed:{
    secondfilter: function(){
        let myfilter=[]
        if(this.filterarray.length==0)
           return this.productslist
        else{
             this.productslist.forEach((product)=>{
             Object.values(product.product).forEach(val =>{
                 if(this.filterarray.includes(val)) {if(! myfilter.includes(product.product)) myfilter.push(
                     {
                         doc_id:product.doc_id,
                         product:product.product
                     }
                     )}                   
             }
                 )});
        return myfilter

        }
         
        
    },
  

         
        
  
  
}
}
</script>
<style lang="scss" >
:root {
  --main-bg-color: #1d4684;
  
}
.main{
    padding-top: 50px;
   
}
.filters ul{
    padding: 0;
    margin: 0;
    list-style-type: none;
    li{
        padding: 0;
        margin: 0;
       
    }
}
.deletefiltericon
{
    &:hover{
    cursor: pointer;
    color: white;
    background-color: black;

}

}
.card .computer-card-image{
    cursor: pointer;
}
.card-body .model{
    background-color: var(--main-bg-color);
    color: white;
}

</style>