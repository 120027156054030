<template>
  <div class="home m-0 p-0 pt-3"> 
     
      <slider />
      <section class="policyshop pt-5">
      <div class="row  justify-content-center">
            <div class=" col-xl-2 col-md-5 col-sm-5 m-1  border bg-light p-3 d-flex ">
                      <div class="align-self-center bg-primary text-white p-3 border">
                        <i class="far fa-truck"></i>
                      </div>
                      <div class="  mr-auto pl-2">
                        <h5>Kostenlos versand</h5>         
                       ab 50€
                      </div>
            
            </div>
               <div class=" col-xl-2 col-md-5 col-sm-5 m-1 border bg-light p-3 d-flex ">
                      <div class="align-self-center bg-danger text-white p-3 border">
                        <i class="fas fa-exchange-alt"></i>
                      </div>
                      <div class="  mr-auto pl-2">
                        <h5>Rücksendung</h5>
                        30 Tage         
                      
                      </div>
            
            </div>
              <div class="col-xl-2 col-md-5 col-sm-5 m-1 border bg-light p-3 d-flex ">
                      <div class="align-self-center bg-success text-white p-3 border">
                        <i class="far fa-credit-card"></i>
                      </div>
                      <div class=" mr-auto pl-2">
                        <h5>Zahlung Methode</h5>         
                      
                      </div>
            
            </div>
              <div class=" col-xl-2 col-md-5 col-sm-5 m-1 border bg-light p-3 d-flex">
                      <div class="align-self-center bg-info text-white p-3 border">
                        <i class="fas fa-globe"></i>
                      </div>
                      <div class="  mr-auto pl-2">
                        <h5>Service 24/7</h5>         
                      
                      </div>
            
            </div>
             <div class=" col-xl-2 col-md-5 col-sm-5 m-1 border bg-light p-3 d-flex ">
                      <div class="align-self-center bg-dark text-white p-3 border">
                        <i class="far fa-id-card"></i>
                      </div>
                      <div class="  mr-auto pl-2">
                        <h5>Sichere Zahlung</h5>         
                      
                      </div>
            
            </div>
      </div>
        
      </section>
         <section class="aktuell ">  
               <div class="container">
               <sectionheader headertitle="aktuell" />
                    
               <div class="row  " >
                   <artikel class="col-xl-3 col-md-6 mb-2"    v-for="index in 4"  :key="index"
                                 :newproduct="akteullarray[index]" />  
                       
                </div>
                <button class="btn btn-primary mt-3" v-if="akteullarray.length>4">Mehr...</button>

               </div>
             
        </section>
        <section class="topangebote">
          <div class=" container ">
              <sectionheader headertitle="Angebote" />
                <div class="row " >
                   <artikel class="col-xl-3 col-md-6 mb-2"    v-for="index in 4"  :key="index"
                                 :newproduct="wochenangebote[index]" />  
                       
                </div>
                <button class="btn btn-primary mt-3" v-if="wochenangebote.length>4">Mehr...</button>
          </div>
        </section>
        <section class="styconnected">
          <div class="container">
            <div class="row">
              <div class="col-xl-4 text-white ">
                <i class="far fa-envelope"></i>
                Stay Connected With Our Email Updates
                </div>
            <div class="col-xl-5">
              <div class="input-group mb-2 mr-sm-2">
    
                  <input type="text" class="form-control" v-model="stayconnectedemail" id="inlineFormInputGroupUsername2" placeholder="Email Addresse...">
                  <div class="input-group-prepend bg-danger">
                       <button class="btn  input-group-text" @click="styconnected()">Sign Up</button>
                  </div>
              </div>
            </div>
            <div class="col-xl-3">
              <ul class="socialmedia">
                <li><i class="fab fa-facebook-f"></i></li>
                <li><i class="fab fa-twitter"></i></li>
                <li><i class="fab fa-instagram"></i></li>
                <li><i class="fab fa-google-plus-g"></i></li>

              </ul>

            </div>

            </div>
            
          </div>

        </section>
        <section class="blogs pt-5" >
          <div class="container">
          <sectionheader headertitle="Blogs" />
             <div class="row overflow-hidden">
                        <div class="col-xl-12">
                            <div class="row">
                              <div class="col border p-1 filter" id="bloggallfilter" style=" cursor: pointer;"
                               @click.prevent="addclass('bloggallfilter')">
                              All</div>
                              <div class="col border p-1 " id="bloggcomputerfilter" style=" cursor: pointer;"
                               @click.prevent="addclass('bloggcomputerfilter')"
                              >computer</div>
                              <div class="col border p-1 " id="bloggmonitorfilter" style=" cursor: pointer;"
                               @click.prevent="addclass('bloggmonitorfilter')"
                              >monitor</div>
                            </div>
                        </div>
                        <div class="card blogCard col-12 mb-1 p-0"  v-for="blog in filteredbloglist" :key="blog">
                            <div class="row no-gutters" >
                              <div class="col-md-4 p-2">
                                <img :src="require(`../assets/computer.jpg`)" class="card-img" alt="...">
                              </div>
                              <div class="col-md-8">
                                <div class="card-body">
                                  <h5 class="card-title text-left">{{blog.blog.title}}</h5>
                                   <p class="card-text "><small class="text-muted"></small></p>
                                  <p class="card-text text-left">{{blog.blog.text.substring(0,300)}}...
                                  <router-link to="/blog" >
                                  <p class="card-text"><small class="text-muted">mehr lesen</small></p>
                                  </router-link></p>
                                </div>
                              </div>
                            </div>
                          </div>
                       
                         
                        
                       
                      </div>
                 </div>
         </section> 
         <section class="testimonial ">
           <div class="container">
             <sectionheader headertitle="Feedback" />
              <h1 class="text-center "> Was unsere Kunden sagen</h1>
             <div class="row  justify-content-around  overflow-hidden">
              
               <div class="col-xl-4 col-md-6 pt-5">
                  <div class="card  " >
                  <div class="card-header  position-relative" style="height:50px"> 
                    <img src="../assets/desktop.png" style="height:100px; width:100px ;top:-50px ;left:35%" class="position-absolute border rounded-circle">
                  </div>
                  <div class="card-body ">
                    
                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maiores aperiam nisi, minima dicta suscipit molestias mollitia tempora, 
                      a ad ut fuga, esse qui delectus nostrum reprehenderit. Quae molestiae quisquam amet.</p>
                      <h3>Ahmad Alasaf</h3>
                  </div>
                </div>

               </div>
                <div class="col-xl-4 col-md-6  pt-5">
                   <div class="card  " >
                  <div class="card-header  position-relative" style="height:50px"> 
                    <img src="../assets/desktop.png" style="height:100px; width:100px ;top:-50px ;left:35%" class="position-absolute border rounded-circle">
                  </div>
                  <div class="card-body ">
                    
                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maiores aperiam nisi, minima dicta suscipit molestias mollitia tempora, 
                      a ad ut fuga, esse qui delectus nostrum reprehenderit. Quae molestiae quisquam amet.</p>
                      <h3>Ahmad Alasaf</h3>
                  </div>
                </div>

               </div>
                <div class="col-xl-4 col-md-6 pt-5">
                    <div class="card  " >
                  <div class="card-header  position-relative" style="height:50px"> 
                    <img src="../assets/desktop.png" style="height:100px; width:100px ;top:-50px ;left:35%" class="position-absolute border rounded-circle">
                  </div>
                  <div class="card-body ">
                    
                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maiores aperiam nisi, minima dicta suscipit molestias mollitia tempora, 
                      a ad ut fuga, esse qui delectus nostrum reprehenderit. Quae molestiae quisquam amet.</p>
                      <h3>Ahmad Alasaf</h3>
                  </div>
                </div>

               </div>
             </div>            
           </div>
        </section> 
        <section class="contact ">
          <div class="container">
            <sectionheader headertitle="Kontack" />
            <div class="form">
              <div class="row">
                <div class="col-xl-4 col-md-12">
                <input type="text" v-model="contactName" class="form-control mb-3" placeholder="Name...">
              </div>
              <div class="col-xl-4 col-md-12">
                <input type="email" v-model="contactEmail" class="form-control mb-3" placeholder="EMail...">
              </div>
              <div class="col-xl-4 col-md-12">
                 <input type="text" v-model="contactNummer" class="form-control mb-3" placeholder="Phone...">
              </div>
              <div class="col-12">
                <textarea class="form-control mb-3"  v-model="contactMessage"></textarea>
              </div>

              </div>
              <button class="btn btn-primary" @click.prevent="sendMessage()">Senden</button>
            </div>
          </div>
        </section>
        <footercomponent />
  </div>
</template>
<script>
import slider from "../components/slider"
import db from "../components/firebaseInit";
import sectionheader from "../components/sectionheader";
import artikel from "../components/product";
import footercomponent from "../components/footercomponent"
import Footercomponent from '../components/footercomponent.vue';
import Sectionheader from '../components/sectionheader.vue';
export default {
  name: "Home",
  components: {
   slider,
   sectionheader,
   artikel ,
   footercomponent,
    Footercomponent,
    Sectionheader, 
    
   
  },
   data(){

       return{
              imageheight: null ,
              navbarheight: null,
              stayconnectedemail:null,
              msg: null,
              productslist:null,
              position:0,
              wochenangebote:null,
              akteullarray:[],
              contactName:null,
              contactEmail:null,
              contactNummer:null,
              contactMessage:null,
              filtered:false,
              bloglist:null,
              filteredbloglist:[],
              blogtitle:null,
              blogtext:null,
              blogcategory:null,
     
    
   }
 },
 created(){

   setTimeout(() => {
      db.collection("products").onSnapshot((query)=>{
            this.productslist=[];
             this.wochenangebote=[]
            query.forEach((doc)=>{
                this.productslist.push({
                doc_id:doc.id,
                product_Laufwerk:doc.data().product_Laufwerk,
                product_type:doc.data().product_type,
                product_Netzwerk:doc.data().product_Netzwerk,
                product_amount:doc.data().product_amount,
                product_arbeitsspeichertechnologie:doc.data().product_arbeitsspeichertechnologie,
                product_arbeitsspeicher:doc.data().product_arbeitsspeicher,
                product_color:doc.data().product_color,
                product_category:doc.data().product_category,
                product_ausstatung:doc.data().product_ausstatung,
                product_description:doc.data().product_description,
                product_festplatte:doc.data().product_festplatte,
                product_festplattetechnologie:doc.data().product_festplattetechnologie,
                product_grafikkarte:doc.data().product_grafikkarte,
                product_grafikkartehersteller:doc.data().product_grafikkartehersteller,
                product_hersteller:doc.data().product_hersteller,
                product_imageslist:doc.data().product_imageslist,
                product_kerne:doc.data().product_kerne,
                product_model:doc.data().product_model,
                product_price:doc.data().product_price,
                product_prozessor:doc.data().product_prozessor,
                product_prosessorhersteller:doc.data().product_prosessorhersteller,
                product_sound:doc.data().product_sound,
                product_zustand:doc.data().product_zustand,
                 product_reduse:doc.data().product_reduse,
                 product_date:doc.data().product_date,

                }
                   )
            })
            query.forEach((doc)=>{
              if(doc.data().product_reduse)
              {
                  this.wochenangebote.push({
                     doc_id:doc.id,
                product_Laufwerk:doc.data().product_Laufwerk,
                product_type:doc.data().product_type,
                product_Netzwerk:doc.data().product_Netzwerk,
                product_amount:doc.data().product_amount,
                product_arbeitsspeichertechnologie:doc.data().product_arbeitsspeichertechnologie,
                product_arbeitsspeicher:doc.data().product_arbeitsspeicher,
                product_color:doc.data().product_color,
                product_category:doc.data().product_category,
                product_ausstatung:doc.data().product_ausstatung,
                product_description:doc.data().product_description,
                product_festplatte:doc.data().product_festplatte,
                product_festplattetechnologie:doc.data().product_festplattetechnologie,
                product_grafikkarte:doc.data().product_grafikkarte,
                product_grafikkartehersteller:doc.data().product_grafikkartehersteller,
                product_hersteller:doc.data().product_hersteller,
                product_imageslist:doc.data().product_imageslist,
                product_kerne:doc.data().product_kerne,
                product_model:doc.data().product_model,
                product_price:doc.data().product_price,
                product_prozessor:doc.data().product_prozessor,
                product_prosessorhersteller:doc.data().product_prosessorhersteller,
                product_sound:doc.data().product_sound,
                product_zustand:doc.data().product_zustand,
                 product_reduse:doc.data().product_reduse,
                 product_date:doc.data().product_date,
                  })
              }

            })
             query.forEach((doc)=>{
              if(doc.data().product_date< new Date("2021 03 01"))
              {
                  this.akteullarray.push({
                     doc_id:doc.id,
                product_Laufwerk:doc.data().product_Laufwerk,
                product_type:doc.data().product_type,
                product_Netzwerk:doc.data().product_Netzwerk,
                product_amount:doc.data().product_amount,
                product_arbeitsspeichertechnologie:doc.data().product_arbeitsspeichertechnologie,
                product_arbeitsspeicher:doc.data().product_arbeitsspeicher,
                product_color:doc.data().product_color,
                product_category:doc.data().product_category,
                product_ausstatung:doc.data().product_ausstatung,
                product_description:doc.data().product_description,
                product_festplatte:doc.data().product_festplatte,
                product_festplattetechnologie:doc.data().product_festplattetechnologie,
                product_grafikkarte:doc.data().product_grafikkarte,
                product_grafikkartehersteller:doc.data().product_grafikkartehersteller,
                product_hersteller:doc.data().product_hersteller,
                product_imageslist:doc.data().product_imageslist,
                product_kerne:doc.data().product_kerne,
                product_model:doc.data().product_model,
                product_price:doc.data().product_price,
                product_prozessor:doc.data().product_prozessor,
                product_prosessorhersteller:doc.data().product_prosessorhersteller,
                product_sound:doc.data().product_sound,
                product_zustand:doc.data().product_zustand,
                 product_reduse:doc.data().product_reduse,
                 product_date:doc.data().product_date,

                   })
              }

            })
           
        }).catch(err=>{console.log(err)})
         
     
   }, 500);
   setTimeout(()=>{
      db.collection("blogs").onSnapshot((query)=>{
            this.bloglist=[];
           
            query.forEach((doc)=>{
              this.bloglist.push({blog:doc.data()})
                 this.filteredbloglist.push({blog:doc.data()})
            })
          }).then(()=>{
             
            }).catch(err=>{console.log("blogggggggggggs"+err)})
         

   },500);
   
      
    


         
            
        
 },
 methods:{
   getsliderheight(){
     return  window.innerHeight-56;
   },
  
   next()
        {
            if(this.position<(this.productslist.length))
            {
                  this.position++;
                   let slide= document.getElementById("slide")
                   slide.style.left =-this.position*298+"px";
            }
            else{
               this.position=0;
                   let slide= document.getElementById("slide")
                   slide.style.left =-this.position*298+"px";

            }
          
        },
   prev()
        {
           if(this.position>0)
           {
               this.position--
               let slide= document.getElementById("slide")
            slide.style.left =-this.position*300+"px";

           }            
        },
  styconnected(){
    db.collection("emails").add({
      email:this.stayconnectedemail,
    }).then(()=>{
      alert(this.stayconnectedemail+"Ihre Email Addresse gespeichert")
      this.stayconnectedemail=null
    }).catch(err=>{console.log(err.msg)})
  },
  sendMessage(){
    db.collection("messages").add({
      name:this.contactName,
      Email:this.contactEmail,
      Nummer:this.contactNummer,
      Message:this.contactMessage,
    }).then(()=>{
      this.contactName=null
      this.contactEmail=null
      this.contactNummer=null
      this.contactMessage=null

    })
  },
  addclass(id){
    document.getElementById("bloggallfilter").classList.remove('filter')
    document.getElementById("bloggcomputerfilter").classList.remove('filter')
    document.getElementById("bloggmonitorfilter").classList.remove('filter')
    document.getElementById(id).classList.add('filter')
    this.blogcategory=document.getElementById(id).innerText
         
          this.filteredbloglist=this.bloglist
          if(this.blogcategory=='All')
            {
              this.filteredbloglist=this.bloglist
            }
            else{
                 this.filteredbloglist=this.bloglist.filter(blog=>blog.blog.category==this.blogcategory)
            }
    
   
    
   
    
  },
  bloglistfilter(){

  },
   onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      console.log(files[0].name);//object contain: name and .....
      var reader = new FileReader();
      if (!files.length)
        return;
      reader.readAsDataURL(files[0]);//The Blob or File from which to read.
       reader.onload = () => {
       // this.image = e.target.result;
        if(this.imageslist!=null)
            this.imageslist.push(files[0].name);
        else{
            this.imageslist=[];
            this.imageslist.push(files[0].name);
        }
    
      //  console.log(e.target.result);// path aber in base64 base64
      };
    
    },
 },

}
</script>
<style lang="scss" >
:root {
  --main-bg-color: #1d4684;
  
}

  .overlay{

   padding: 100px 100px;
   width: 100%;
   height: 100%;
   left: 0;
   top: 0;
   position: absolute;
   background-color: rgba(0,0,0,0.6);
   z-index: 2;
 

  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
 
  }

  .slidersection{
 /*     background-image: url("../assets/background.jpg");
     background-position: center;
/*   background-attachment: fixed; 
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
  height: 1000px;
  margin-top: -20px;
 
  
  }
  .row{
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .policyshop .row  div:hover{
   
    
       cursor: pointer;
       div:first-child{
         border-radius: 50%;
        
       }
     }
   .policyshop .row div  div:first-child{
     border-radius: 8px;
     transition: all 0.2s ease-in-out;
   }
  
  .aktuell{
   
    .slider {
  width: 100%;
  min-height: 30rem;
  position: relative;
  margin: auto;
  overflow-x: scroll;
  overflow-y: hidden;
}
   
  .slide {
  display: flex;
  position: absolute;
  left: 0;
  transition: 0.5s all ease-in-out;
}
.prev{
    top: 40%;
    left:-25px;
   
   width: 50px !important;
    border-radius: 30px;
    transition: 0.3s all ease-in-out;;
    &:hover{
         width: 70px !important;
         left:-15px;
    }

}
.next{
    top: 40%;
    right: -25px;
      width: 50px !important;
    border-radius: 30px;
    transition: 0.3s all ease-in-out;;
    &:hover{
         width: 70px !important;
         right:-15px;
    }
}
}
.topangebote{
 
    .img-fluid{
      height: 350px;
      width: auto;
    }

}
.styconnected{
  background-color: var(--main-bg-color);
  padding: 25px 5px;
  .input-group-text{
    background-color:  #cb3435;
    color: #fff;
  }
  .socialmedia{
    list-style-type: none;
    padding: 0;
    margin: 0;
     li{
      padding: 5px 10px;
      float: left;
      font-size: 24px;
      transition: all 0.4s ease-in-out;
    

       
      }
      li:nth-child(1){
        color: whitesmoke;
        &:hover{
          background-color: white;
          color: #1d4684;
          border-radius: 50%;
        }
      }
       li:nth-child(2){
        color: whitesmoke;
        &:hover{
          background-color: white;
          color: #1d4684;
          border-radius: 50%;
        }
      }
       li:nth-child(3){
        color: whitesmoke;
        &:hover{
          background-color: white;
          color: #841d37;
          border-radius: 50%;
        }
      }
       li:nth-child(4){
        color: whitesmoke;
        &:hover{
          background-color: white;
          color: #dd021f;
          border-radius: 50%;
        }
      }
  
    }
  }
  .blogs{
     .blogCard{
       &:hover{
         cursor: pointer;
       }
     }
      .filter{
       
       
         
          background-color: #425563;
          color: white;
       
      }
     .slider {
  width: 100%;
  min-height: 420px;
  position: relative;
  margin: auto;
  overflow-x: scroll;
  overflow-y: hidden;
}
   
  .slide {
  display: flex;
  position: absolute;
  left: 0;
  transition: 0.5s all ease-in-out;
}
.prev{
    top: 40%;
    left:-25px;
   
   width: 50px !important;
    border-radius: 30px;
    transition: 0.3s all ease-in-out;;
    &:hover{
         width: 70px !important;
         left:-15px;
    }

}
.next{
    top: 40%;
    right: -25px;
      width: 50px !important;
    border-radius: 30px;
    transition: 0.3s all ease-in-out;;
    &:hover{
         width: 70px !important;
         right:-15px;
    }
}
  }
  .testimonial{ 
    h1:nth-child(2){
      position: relative;
      margin: 30px 10px;
      &::after{
        content: "";
        position: absolute;
        display: block;
        border-radius: 40%;
        height: 3px;
        width: 33%;
        left: 33%;
         margin: 10px 10px;
        background-color: goldenrod;
      }
   
    }
    .main{padding-top: 40px !important; padding-bottom: 40px !important;}
    .card .card-header{
      background-color: white !important;;
      border-bottom: none !important;
    }
    .card {
      transition: all 0.2s ease-in-out;      
      &:hover{
        img{
             border: 2px solid goldenrod !important;
        }
       .card-body h3{ color: goldenrod;}
      }     
    }
    .card .card-body{
      h3{color: black;}
    }
  } 
  .contact{
    padding-top: 40px;
    padding-bottom: 40px;
  } 
</style>
