<template>
    <div>
       <einlogencomponent />
    </div>
</template>
<script>
import einlogencomponent from "../components/einlogencomponent"
export default {
    name:"einlogen",
    components:{
        einlogencomponent
    }
}
</script>