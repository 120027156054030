<template>
    <div class="">         
        <section class="productdetail">
            <div class="container bg-white">
                    <div class="model">
                        <h3 class=" text-left p-3">{{product.product_model}}</h3>
                    </div>
                    <div class="row">
                        <div class="col-lg-5 ">
                            <div class="row">
                                <div class="col-3  pt-2 pb-2">                                    
                                    <ul>
                                        <li v-for="image in imageslist" :key="image">
                                            <img style="width:120px ; height: auto" 
                                            class="img-fluid img-thumbnail"
                                            :src="require(`../assets/${image}`)"
                                            @mouseover="previewimage=image"
                                            @mousedown="previewimage=image"                                             
                                            >
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-9  align-self-center " id="priview">
                                    <div class="position-relative ">                                         
                                   <img :src="require(`../assets/${previewimage}`)"                                       
                                    class="img-fluid img-thumbnail "
                                    :id="productId"
                                     @mousemove="imageZoom(productId)"
                                     >
                                     <div id="myresult" class="img-zoom-result  d-none d-lg-block"></div>                                     
                                    </div>                                   
                                </div>
                            </div>
                        </div>
                        <div class=" Ausstatungs col-lg-4 p-0  text-left " id="Ausstatungs">
                            
                            <h5 class="bg-info p-3 text-white ">Ausstatungs</h5>
                            <div class="row pl-2 border-bottom" v-if="product.product_category=='Computer'">
                                <div class="col-4">Prozessor</div>
                                <div class="col-8">{{product.product_prosessorhersteller}}{{product.product_prozessor}} </div>
                            </div>
                            <div class="row pl-2 border-bottom" v-if="product.product_category=='Computer'">
                                <div class="col-4">Arbeitsspeicher</div>
                                <div class="col-8">{{product.product_arbeitsspeicher}}</div>
                            </div>
                            <div class="row pl-2 border-bottom" v-if="product.product_category=='Computer'">
                                <div class="col-4">Festplatte</div>
                                <div class="col-8">{{product.product_festplatte}}</div>
                            </div>
                            <div class="row pl-2 border-bottom" v-if="product.product_category=='Computer'">
                                <div class="col-4">GrafikKarte</div>
                                <div class="col-8">{{product.product_grafikkartehersteller}}{{product.product_grafikkarte}}</div>
                            </div>
                            <div class="row pl-2 border-bottom" v-if="product.product_category=='Computer'">
                                <div class="col-4">Betriebsystem</div>
                                <div class="col-8">{{product.product_software}}</div>
                            </div>
                            <div class="row pl-2 border-bottom" v-if="product.product_category=='Monitor'">
                                <div class="col-4">Hersteller</div>
                                <div class="col-8">{{product.product_hersteller}}</div>
                            </div>
                            <div class="row pl-2 border-bottom" v-if="product.product_category=='Monitor'">
                                <div class="col-4">Modell</div>
                                <div class="col-8">{{product.product_model}}</div>
                            </div>
                             <div class="row pl-2 border-bottom" v-if="product.product_category=='Monitor'">
                                <div class="col-4">Size</div>
                                <div class="col-8">{{product.product_size}}"</div>
                            </div>
                            <div class="row pl-2 border-bottom">
                                <div class="col-4">Zustand</div>
                                <div class="col-8">{{product.product_zustand}}</div>
                            </div>
                            <div class="row pl-2 border-bottom">
                                <div class="col-4">Farbe</div>
                                <div class="col-8">{{product.product_color}}</div>
                            </div>

                        </div>
                        <div class="col-lg-3  ">
                            <div class="price border-bottom">
                            <h1 class="text-dark">{{product.product_price * quantity}} €</h1> 
                                <p>inkl. MwSt.</p>
                            </div>
                            <div>
                            
                                <input type="range" class="custom-range" min="1" v-model="quantity" :max="product.product_amount" id="customRange2">
                                <input type="number" class="form-control" min="1" :max="product.product_amount" v-model="quantity">
                                <button class="btn btn-danger mt-2  form-control" @click.prevent="addtowarenkorp(product)">In den Warenkorb</button>
                               
                            </div>
                            
                        </div>
                    </div>
            </div> 
        </section>
        <section class="specifications ">
            <div class="container ">
                  <ul class="nav nav-tabs  " id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <a class="nav-link active " id="Techniche-tab" 
                        data-toggle="tab" href="#Techniche" role="tab" 
                        aria-controls="Techniche" aria-selected="true"> Details</a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" id="Beschreibung-tab" 
                        data-toggle="tab" href="#Beschreibung" role="tab"
                         aria-controls="Beschreibung" aria-selected="false">Beschreibung</a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" id="Zustand-tab" data-toggle="tab" 
                        href="#Zustand" role="tab" aria-controls="Zustand" 
                        aria-selected="false">Zustand</a>
                    </li>
                </ul>
                <div class="tab-content pt-5 text-left bg-white pl-3 pb-5" id="myTabContent">
                        <div class="tab-pane fade show active" id="Techniche" role="tabpanel" aria-labelledby="Techniche-tab">
                           {{product.product_description}}
                            {{product.product_description}}
                        </div>
                        <div class="tab-pane fade" id="Beschreibung" role="tabpanel" aria-labelledby="Beschreibung-tab">
                           {{product.product_description}}
                        </div>
                        <div class="tab-pane fade" id="Zustand" role="tabpanel" aria-labelledby="Zustand-tab">
                          {{product.product_description}}
                        </div>
                </div>

            </div>
              
        </section>    
    </div>
</template>
<script>
import db from "../components/firebaseInit"; 
export default {
    name:"details",
    props:['productId'],
    data(){
        return{
                product:null,           
                imageslist:null,
                previewimage:null,
                quantity:1,
                bestellungen:null,
                img:null, 
                lens:null,
                result:null,
                cx:null,
                cy:null,
                x:null,
                y:null           
        }
    },
    created(){
        db.collection("products").doc(this.productId).get().then((doc)=>{
                this.imageslist=[];
                this.imageslist=doc.data().product_imageslist;  
                         
               this.product=doc.data();
            this.previewimage=this.imageslist[0];
        })
     /*    ////
        const image=document.getElementById(this.product.doc_id)
        image.addEventListener('mouseover',this.imageZoom(`"${this.product.doc_id}`,"myresult"));
  
          */  
         this.getcartPos                              

    },
    computed:{
        slidewidth(){
            return document.getElementById("slider").offsetWidth
        },
        productwidth(){
            return document.getElementsByClassName("component")[0].offsetWidth

        },
        componentperslider(){
          return Math.floor(this.slidewidth/this.productwidth)
        },
        getcartPos() {
          let cart =document.getElementById("warenkorp")
   
   
    /*get the x and y positions of the image:*/
 
   let cartpos=cart.getBoundingClientRect();
    /*calculate the cursor's x and y coordinates, relative to the image:*/
   let x =  cartpos.left;
   let y =  cartpos.top;
    /*consider any page scrolling:*/
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
   
    return {x : x, y : y};
  },

    },
    methods:{
        imageZoom(imgID) {
             
  this.img = document.getElementById(imgID);
 
        this.result = document.getElementById("myresult");
  /*create t:*/
  if(!this.lens) 
       this.lens = document.createElement("DIV");
   this.lens.setAttribute("class", "img-zoom-lens");
         /*insert t:*/
  this.img.parentElement.insertBefore(this.lens, this.img);
  /*calculate the ratio between result DIV and t:*/
  this.cx = this.result.offsetWidth / this.lens.offsetWidth;
  this.cy = this.result.offsetHeight / this.lens.offsetHeight;
  /*set background properties for the result DIV:*/
  this.result.style.backgroundImage = "url('" + this.img.src + "')";
  this.result.style.backgroundSize = (this.img.width * this.cx) + "px " + (this.img.height * this.cy) + "px";
  /*execute a function when someone moves the cursor over the image, or the t:*/
  this.lens.addEventListener("mousemove", this.lensmove)
  this.img.addEventListener("mousemove", this.lensmove)

  
 
 
 
 
 
        },
  lensmove(e){
 
    /*prevent any other actions that may occur when moving over the image:*/
    e.preventDefault();
    /*get the cursor's x and y positions:*/
     let pos = this.getCursorPos(e);
    /*calculate the position of the t:*/
   let x = pos.x - (this.lens.offsetWidth / 2);
   let y = pos.y - (this.lens.offsetHeight / 2);
    /*prevent the t from being positioned outside the image:*/
    if (x > this.img.width - this.lens.offsetWidth) {x = this.img.width - this.lens.offsetWidth;}
    if (x < 0) {x = 0;}
    if (y > this.img.height - this.lens.offsetHeight) {y = this.img.height - this.lens.offsetHeight;}
    if (y < 0) {y = 0;}
    /*set the position of the t:*/
    this.lens.style.left = x + "px";
    this.lens.style.top = y + "px";
    /*display what the t "sees":*/
    this.result.style.backgroundPosition = "-" + (x * this.cx) + "px -" + (y * this.cy) + "px";

  }, 
   getCursorPos(e) {
   
    e = e || window.event;
    /*get the x and y positions of the image:*/
   let a = this.img.getBoundingClientRect();
    /*calculate the cursor's x and y coordinates, relative to the image:*/
   this.x = e.pageX - a.left;
   this.y = e.pageY - a.top;
    /*consider any page scrolling:*/
    this.x = this.x - window.pageXOffset;
    this.y = this.y - window.pageYOffset;
   
    return {x : this.x, y : this.y};
  },
        addtowarenkorp(bestellung){
                        let found=false
                              if((JSON.parse(localStorage.getItem('bestellungen'))==null))
                              {
                                   this.bestellungen=[]
                                    bestellung.amount=this.quantity;
                                bestellung.id=this.productId;                               
                                this.bestellungen.push(bestellung);
                                this.$store.dispatch('addnewbestellung');
                                localStorage.setItem('bestellungen',JSON.stringify(this.bestellungen)); 
                             /*     const image=document.getElementById("testtransform");                              
                                  image.classList.add("testtransform")  
                                setTimeout(()=>{image.classList.remove("testtransform")}  ,500)  */
                              }
                              else{
                                 this.bestellungen=JSON.parse(localStorage.getItem('bestellungen'))
                                  this.bestellungen.forEach(element => { 
                                     if(element.id==this.productId)
                                     {
                                         alert("Diese Artikel ist schon im Warenkorp")
                                         found=true
                                     }

                                 });
                                  if(!found)
                                {
                                bestellung.amount=this.quantity;
                                bestellung.id=this.productId;                               
                                this.bestellungen.push(bestellung);
                                this.$store.dispatch('addnewbestellung');
                                localStorage.setItem('bestellungen',JSON.stringify(this.bestellungen));    
                              /*     const image=document.getElementById("testtransform");
                                 image.classList.add("testtransform")   
                           
                                setTimeout(()=>{image.classList.remove("testtransform")}  ,500) */
                                }
                              }
                           
        },
        next()
        {
            if(this.position<(this.productslist.length-4))
            {
                  this.position++;
                   let slide= document.getElementById("slide")
                   slide.style.left =-this.position*298+"px";
            }
          
        },
         prev()
        {
           if(this.position>0)
           {
               this.position--
               let slide= document.getElementById("slide")
            slide.style.left =-this.position*300+"px";

           }
            
        }
    }
}
</script>
<style lang="scss" >
.img-zoom-result {
 
  /*set the size of the result div:*/
  width: 100%;
  height: 200px; 
}

section{
   
    background-color: rgb(248, 245, 245);
    padding-bottom: 50px;
   
}
.testtransform{   
          transition: .5s all ease-in-out;
              transform: translateY(-300px) translateX(943px);
              height: 30px !important;             
}
.productdetail{
    
    .col-5{
        padding: 0 !important;
    }
    ul{
        margin: 0;
        padding: 0;
        list-style-type: none;
        li{
           margin-bottom: 2px;
           cursor: pointer;
           &:hover{
               box-shadow: 3px 3px 3px #999;
           }
        }
    }
    .Ausstatungs{
        .col-4, .col-8{
            padding: 8px 0px !important;
        }
    }
}
@media (min-width:992px)
{
    .img-zoom-lens {
  position: absolute;
  border: 1px solid #851515;
  /*set the size of the t:*/
  width: 40px;
  height: 40px;
}

}
</style>