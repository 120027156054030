<template>
    <div class="">       
        <div class="container userprofile">
            <div class="row">                
                <div class="col ">
                    <div class="row">
                            <div class="col-12">
                                <i class="fas fa-user-circle fa-8x text-primary"></i><br>
                                <p class="text-primary">{{user.email}}</p>
                            </div>  
                            <div class="col">
                                <ul class="addresse list-group">
                                    <li class="list-group-item text-left " style="width:50%; margin:auto">
                                     <h5 class="d-inline  p-2">
                                          Strasse:
                                    </h5>
                                    <p class=" p-2 text-dark d-inline ">{{user.strasse}}</p>
                                        <input type="text" class="form-control" :placeholder="`${user.strasse}...`"
                                        v-model="strasse" v-if="strassenflag">  
                                        <button class="btn btn-primary  float-right"
                                        v-if="!strassenflag"
                                         @click.prevent="strassenflag=!strassenflag">ändern</button>
                                          <button class="btn btn-danger  float-right"
                                        v-if="strassenflag"
                                         @click="changestrasse()">speicern</button>
                                    </li >
                                     <li class="list-group-item text-left" style="width:50%; margin:auto">
                                       <h5  class="d-inline  p-2">  Hausnummer: </h5>  
                                         <p class=" p-2 text-dark d-inline ">{{user.hausnummer}}</p>
                                        <input type="text" class="form-control" :placeholder="`${user.hausnummer}...`"
                                        v-model="hausnummer" v-if="hausnummerflag">  
                                        <button class="btn btn-primary  float-right"
                                        v-if="!hausnummerflag"
                                         @click.prevent="hausnummerflag=!hausnummerflag">ändern</button>
                                          <button class="btn btn-danger  float-right"
                                        v-if="hausnummerflag"
                                         @click="changehausnummer()">speicern</button>
                                    </li >
                                     <li class="list-group-item text-left" style="width:50%; margin:auto">
                                     <h5  class="d-inline  p-2">
                                          PostLeitzahl:
                                    </h5>  
                                         <p class=" p-2 text-dark d-inline ">{{user.post}}</p>
                                        <input type="text" class="form-control" :placeholder="`${user.post}...`"
                                        v-model="post" v-if="postflag">  
                                        <button class="btn btn-primary  float-right"
                                        v-if="!postflag"
                                         @click.prevent="postflag=!postflag">ändern</button>
                                          <button class="btn btn-danger  float-right"
                                        v-if="postflag"
                                         @click="changepost()">speicern</button>
                                    </li >
                                     <li class="list-group-item text-left" style="width:50%; margin:auto">
                                     <h5  class="d-inline  p-2">
                                          Stadt oder Dorf:
                                    </h5>  
                                        <p class=" p-2 text-dark d-inline ">{{user.stadt}}</p>
                                        <input type="text" class="form-control" :placeholder="`${user.stadt}...`"
                                        v-model="stadt" v-if="stadtflag">  
                                        <button class="btn btn-primary  float-right"
                                        v-if="!stadtflag"
                                         @click.prevent="stadtflag=!stadtflag">ändern</button>
                                          <button class="btn btn-danger  float-right"
                                        v-if="stadtflag"
                                         @click="changestadt()">speicern</button>
                                    </li >

                                </ul>
                      
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import db from "../components/firebaseInit"
export default {
    name:"userprofile",
    data(){
        return{
            user:null,
            currentuser:this.$store.getters.user.email,
            strassenflag:null,
            hausnummerflag:null,
            hausnummer:null,
            postflag:null,
            post:null,
            stadtflag:null,
            stadt:null,
            strasse:null

        }
    },
    created(){
        
        db.collection("users").where('email','==',this.$store.getters.user.email).get().then((users)=>{
            users.forEach((user)=>{
                            this.user={}
                            this.user.id=user.id
                            this.user.email=user.data().email

                            this.user.strasse=user.data().strasse
                            this.user.hausnummer=user.data().hausnummer
                            this.user.post=user.data().post
                            this.user.stadt=user.data().stadt                   
                                                            
                             })
        })

    },
    methods:{
        changestrasse(){
            this.strassenflag=!this.strassenflag;
             db.collection('users').doc(this.user.id).update({               
                    strasse:this.strasse
                
             }).then(()=>{
                    db.collection("users").where('email','==',this.$store.getters.user.email).get().then((users)=>{
                    users.forEach((user)=>{
                        this.user={}
this.user.id=user.id
this.user.email=user.data().email

this.user.strasse=user.data().strasse
this.user.hausnummer=user.data().hausnummer
this.user.post=user.data().post
this.user.stadt=user.data().stadt
                    })
           

        })
              
             }).catch((error) => {console.error("Error updating document: ", error);});
           
        },
           changehausnummer(){
            this.hausnummerflag=!this.hausnummerflag;
             db.collection('users').doc(this.user.id).update({
                 hausnummer:this.hausnummer
             }).then(()=>{
                    db.collection("users").where('email','==',this.$store.getters.user.email).get().then((users)=>{
                    users.forEach((user)=>{
                     this.user={}
this.user.id=user.id
this.user.email=user.data().email

this.user.strasse=user.data().strasse
this.user.hausnummer=user.data().hausnummer
this.user.post=user.data().post
this.user.stadt=user.data().stadt
                    })
           

        })
              
             }).catch((error) => {console.error("Error updating document: ", error);});
           
        },
           changepost(){
            this.postflag=!this.postflag;
             db.collection('users').doc(this.user.id).update({
                 post:this.post
             }).then(()=>{
                    db.collection("users").where('email','==',this.$store.getters.user.email).get().then((users)=>{
                    users.forEach((user)=>{
                      this.user={}
this.user.id=user.id
this.user.email=user.data().email

this.user.strasse=user.data().strasse
this.user.hausnummer=user.data().hausnummer
this.user.post=user.data().post
this.user.stadt=user.data().stadt
                    })
           

        })
              
             }).catch((error) => {console.error("Error updating document: ", error);});
           
        },
        changestadt(){
            this.stadtflag=!this.stadtflag;
             db.collection('users').doc(this.user.id).update({
                 stadt:this.stadt
             }).then(()=>{
                    db.collection("users").where('email','==',this.$store.getters.user.email).get().then((users)=>{
                    users.forEach((user)=>{
                 this.user={}
this.user.id=user.id
this.user.email=user.data().email

this.user.strasse=user.data().strasse
this.user.hausnummer=user.data().hausnummer
this.user.post=user.data().post
this.user.stadt=user.data().stadt
                    })
           

        })
              
             }).catch((error) => {console.error("Error updating document: ", error);});
           
        }
    },
    computed(){

    }
}
</script>
<style lang="scss" scoped>
.userprofile{
    padding-top: 50px !important;
}
</style>