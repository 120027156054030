<template>
    <div class="">     
      <div class="row pt-4">
          <div class="col-xl-8">       
             <ul class=" list-group ">
            <li class="list-group-item text-left  border rounded mb-2" 
            v-for="(bestellung,index) in cart " :key="bestellung">           
                <div class="row">
                    <div class=" col-xs-12 col-lg-4   text-left">
                         <img :src="require(`../assets/${bestellung.product_imageslist[1]}`)"
                         
                          class="img-thumbnail border-0 mt-1 mb-1    " alt="" >
                     </div>
                    <div class=" col-xs-12 col-lg-8 text-left ">
                        <h3 class="bg-dark text-light d-block border radius-3 text-left col-12 p-1 mb-3 rounded">
                          <router-link :to="{name:'details',params:{'productId':bestellung.id}}"  > {{bestellung.product_model}}</router-link>
                             </h3>

                         <div class="col-xs-12  pt-3 "> 
                        <div class="row  ">
                            <div class="col-2 "> 
                                 <button class="btn btn-info float-right" @click.prevent="minus(index)"><i class=" far fa-minus"></i></button>
                            </div>
                             <div class="col-2 text-center">
                                  <span class=""> {{bestellung.amount}} </span>
                             </div>
                              <div class="col-2">
                                   <button class="btn btn-info float-left" @click.prevent="plus(index)"><i class="  far fa-plus"></i></button>
                              </div>
                              <div class="col-4 text-center">
                                  <span class="" v-if="bestellung.product_reduse">  
                                      {{(bestellung.product_price-(bestellung.product_price*bestellung.product_reduse/100))*bestellung.amount}} €</span>
                                   <span class="" v-else>  {{bestellung.product_price*bestellung.amount}} €</span>
                             </div>
                             <div class="col-2">
                                    <button class="btn btn-danger   float-right" @click.prevent="deletebestellung(index)"><i class="fas fa-trash-alt"></i></button>
                              </div>  
                        </div>
                        
                    </div>
                    </div>                   
                </div>          
           
            </li>
             </ul>
          </div>
           <div class="rechnung  col-xl-2" style="background-color:gray ;height:70px">
              <h1 class="bg-danger pt-2 mb-1  ">
                  {{cart.summ}} €
              </h1>
               <router-link to="/kasse" class="btn btn-primary d-block"> Zur Kasse</router-link>
           </div>
      </div>
    </div>
</template>
<script>

export default {
    name: "warenkorp",
    data(){
        return{
              summprices:0,
               cart:null
                }      
       
    },
 
    created(){
       this.getsummprice()
       },
    methods:{
        minus(index){

           if(this.cart[index].amount>1)
           {
            this.cart[index].amount--
         
            localStorage.setItem('bestellungen',JSON.stringify(this.cart))
            this.getsummprice();
           }

        },
        plus(index){
             this.cart[index].amount++
            
             this.cart.summ=this.summprices;
               localStorage.setItem('bestellungen',JSON.stringify(this.cart))
               this.getsummprice();

        },
        deletebestellung(index)
        {
            this.cart.splice(index,1);
            localStorage.setItem('bestellungen',JSON.stringify(this.cart));
            this.$store.dispatch('deletebestellung');
             this.getsummprice();
        },
        getsummprice(){
              this.cart=JSON.parse(localStorage.getItem('bestellungen'));
            this.summprices=0;
             this.cart.forEach(element => {
                 if(element.product_reduse)
                 {
                      this.summprices+=(element.product_price-(element.product_price*element.product_reduse/100))*element.amount

                 }
                 else
                 {  this.summprices+=element.product_price*element.amount}
                 
             });
             this.cart.summ=this.summprices;
              
            
             localStorage.setItem('bestellungen',JSON.stringify(this.cart))
        }
     
     
    }
}
</script>
<style lang="scss" scoped>
 .rechnung{

     position: relative;
     h1{
          position: absolute;
         width: 100%;
         left: 0;

     }
     .btn{
         position: absolute;
         width: 100%;
         left: 0;
         top: 95%;

     }
 }
</style>