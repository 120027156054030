import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import detail from "../views/detail.vue";
import shopingcart from "../views/shopingcart.vue";
import warenkorp from "../views/warenkorp.vue";
import smartphones from "../views/smartphones.vue";
import service from "../views/service.vue";
import details from "../views/details.vue";
import products from "../views/products.vue";

import einlogen from "../views/einlogen.vue"
import userprofile from "../views/userprofile.vue"
import kasse from "../views/kasse.vue"


Vue.use(VueRouter);

const routes = [
  { path: "/",  name: "Home",component: Home  },
  { path: "/detail/:id", name: "detail",  props: true,component: detail }
  ,
  { path: "/details/:productId",name: "details", props: true, component: details },
  { path: "/shopingcart",  name: "shopingcart", component: shopingcart }
  ,
  {path: "/service",name: "service",component: service}
  ,
  { path: "/products/:category",   name: "products",props: true, component: products}
  ,  
  {path: "/smartphones",name: "smartphones", component: smartphones },
  {path: "/warenkorp", name: "warenkorp",component: warenkorp },
  {path: "/einlogen", name: "warenkorp",component: einlogen },
  {path: "/userprofile", name: "warenkorp",component: userprofile },
  {path: "/kasse", name: "warenkorp",component: kasse },
  {path: "/about", name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];

const router = new VueRouter({
  mode: "history", 
  base: process.env.BASE_URL,
  routes,

});

export default router;
