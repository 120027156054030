<template>
    <div class="headerwrapper position-relative text-left " style="min-height:60px">
        <h1 class="headertitle text-left d-inline p-3  position-absolute">{{headertitle}}</h1>
    </div>
</template>
<script>
export default {
    name:"sectionheader",
    props:['headertitle']
}
</script>
<style lang="scss" scoped>
:root {
  --main-bg-color: #1d4684;
  
}
.headerwrapper{
      background-color: var(--main-bg-color);
       border-bottom-left-radius: 50px;
      
       margin-bottom: 20px;
    }
    .headertitle{
       background-color: #0d397c;
       box-shadow: 3px 3px 3px #111;
       color: whitesmoke;
      top: -20px;
      left: 0px;
      border-bottom-left-radius: 50px;
       border-top-right-radius: 50px;
    }
</style>