<template>

  <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel" style="">
  <ol class="carousel-indicators">
    <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
    <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
    <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
  </ol>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="../assets/samsung_slide1.jpg" class="d-block w-100" alt="...">
      <div class="carousel-caption d-none d-md-block text-dark">
        <h5>First slide label</h5>
        <p class="text-dark">Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src="../assets/del_slide1.jpg" class="d-block w-100" alt="...">
      <div class="carousel-caption d-none d-md-block text-dark">
        <h5>Second slide label</h5>
        <p class="text-dark">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src="../assets/hp_slid1.jpg" class="d-block w-100" alt="...">
      <div class="carousel-caption d-none d-md-block text-dark">
        <h5>Third slide label</h5>
        <p class="">Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
      </div>
    </div>
</div>
  <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
    
</template>
<script>
export default {
    name: "slider",
    data(){
            return{
                 
            }
    },
     methods:{
     
      

    },
  
}
</script>
<style lang="scss" scoped>
.carousel{

    
}

</style>