import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
 
  state: {
    warenkorp:JSON.parse(localStorage.getItem('bestellungen')),
    user:JSON.parse(localStorage.getItem('currentUser')),
  
 
  },
  getters:{
    
      getwarenkorp:state=>{ return state.warenkorp},
      user: state => { return state.user },

    
  },
  mutations: {
    setUser: (state,user) => {
       state.user = user;},
        addnewbestellung(state){ state.warenkorp++; },
        deletebestellung(state){ state.warenkorp--}
  },
  actions: {
    addnewbestellung(context){context.commit('addnewbestellung') },
    deletebestellung(context){context.commit('deletebestellung')},
    setUser: (context,user) => { context.commit('setUser',user); },
  },

});
