<template>
    <div class="container pt-5">
        
        <div v-if="habenkonto" class="form  p-5 m-auto" style="max-width:450px">
            <h1 class="text-primary mb-5">Willcommen</h1>
            <input type="email" class="form-control mb-2" v-model="email" placeholder="Email..." >
            <input type="password" class="form-control mb-2"  v-model="password" placeholder="Passwort...">
            <p ><small style="cursor:pointer" class="text-primary" @click.prevent="habenkonto= !habenkonto">Haben Sie noch kein Konto. Konto erstellen?</small></p>
            <button class="btn btn-primary" @click.prevent="einlogen()">Einlogen</button>

        </div>
        <div v-else class="form  p-5 m-auto" style="max-width:450px">
            <h1 class="text-primary mb-5">Registrieren</h1>
            <input type="email" class="form-control mb-2" v-model="email" placeholder="Email..." >
            <input type="password" class="form-control mb-2" v-model="password" placeholder="Passwort...">
            <input type="password" class="form-control mb-2" placeholder="Passwort bestätigen...">
           
            <button class="btn btn-primary" @click.prevent="registieren()">Registrieren</button>

        </div>

    </div>
</template>
<script>
import db from "../components/firebaseInit"
import firebase from "firebase";
import "firebase/auth";
export default {
    name:"einlogencomponent",
    components:{
     

    },
    data(){
        return{
            habenkonto:true,
            email:null,
            password:null,
            logged:false,

        }
    },
    created(){
        firebase.auth().onAuthStateChanged(userAuth =>{              
           if (userAuth) {
                         this.$store.dispatch('setUser',userAuth)
                         localStorage.setItem('currentUser',JSON.stringify(userAuth))
                         this.logged=true                       
                      /*   userAuth.getIdTokenResult()
                        .then(idTokenResult => 
                        {
                          console.log("tokenResult:",idTokenResult.claims);
                          this.usertype=idTokenResult.claims;
                            this.$store.dispatch('setUserType',this.usertype)
                         
                          });
                          if(userAuth.email=="ahmad312alassaf@gmail.com")
                            this.$router.push("/"); */
                        
               }
               else{
                  console.log("user logged out:",userAuth);
                  localStorage.removeItem('currentUser')
                  this.logged=false
                  this.$store.dispatch('setUser',null);
                 //  this.$store.dispatch('setUserType',null)

               }
                });  

    },
    methods:{
        einlogen(){
            firebase.auth().signInWithEmailAndPassword(this.email, this.password)
            .then((user) => {
                // Signed in
                alert(user.user.email)
                this.$router.push("/kasse")
                // ...
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log(errorCode+errorMessage)
            });

        },
        registieren()
        {
          
            firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
                .then((userCredential) => {
                    // Signed in 
                    var user = userCredential.user;
                    db.collection("users").add({
                        email:user.email
                    })
                    // ...
                })
                .catch((error) => {
                    var errorCode = error.code;
                    var errorMessage = error.message;
                      alert( error.message)
                    console.log(errorMessage+errorCode)
                    // ..
                });

        }
    }
}
</script>