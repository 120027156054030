<template >

  <div class="product  position-relative " >
      <div class="card " 
     
    
      >
      
      <img :src="src"  @mouseover="translatex(newproduct.doc_id)"
    
       class="card-img-top  " alt="...">
       
        <span class="reduse  text-left"
       
        v-if="newproduct.product_reduse">{{newproduct.product_price-(newproduct.product_price*newproduct.product_reduse/100)}} </span>
        <span class="price  text-left"
         v-bind:style="newproduct.product_reduse ?'text-decoration: line-through red double ;' : 'border: none;'" 
         >{{newproduct.product_price}} €</span>
          <span class="CPU  text-right"  v-if="newproduct.product_category=='Computer'">{{newproduct.product_prozessor}} </span>
         <span class="RAM  text-right"  v-if="newproduct.product_category=='Computer'">{{newproduct.product_arbeitsspeicher}} </span>
        
      <div class="card-body bg-light pb-3 border-bottom ">
        <h6 class="card-title text-left" >{{newproduct.product_model}}</h6>
       
       
         <router-link :to="{name:'details',params:{'productId':newproduct.doc_id}}"
          class=" atbottom btn btn-primary m-2 " style="">Details...</router-link>
      </div>
     
      
    </div>
</div>



</template>
<script>




export default {
    name: 'product',
    props:['newproduct'],
    components:{
    
    },
    data(){
         return{
           src:null,
   
    }
    },
    methods:{
      changebild(){
         this.src=require(`../assets/${this.newproduct.product.product_imageslist[2]}`);

      },
      translatex(){
  /*       const image=document.getElementById(id);
       image.style.position="absolute"
        image.style.right=-20+"rem"; */
        


      },
      resetbild(){
         this.src=require(`../assets/${this.newproduct.product_imageslist[1]}`);
      }
     
    },
    created(){
      this.src=require(`../assets/${this.newproduct.product_imageslist[1]}`);
      
    
     

    },
    computed(){
      

    }
            
            
          
           
    
    }
    
</script>
<style lang="scss" scoped>
:root {
  --main-bg-color: rgb(29, 70, 132);
  
}

.card{
  position: relative;
  overflow: hidden;
  img{
    transition: all 0.3s ease-in-out;
     &:hover{
    transform: scale(1.1)

  }

  }
 
 
.reduse{
    position: absolute;
    right: -95px;
    top: 33px;
    width: 100px;
     padding: 2px 5px;
    border-radius: 5px;
   background-color: rgba(211, 11, 21, 0.8);
    color:white;
   
      transition: 0.8s all ease-in-out;
  }
  .price{
    position: absolute;
    top: 0px;
    right: -95px;
    display: block;
   width: 100px;
    border-radius: 5px;
    padding: 2px 5px;
     background-color: rgba(112, 115, 119, 0.6);
    color:black;
   
      transition: 0.7s all ease-in-out;
  }
  .RAM{
    position: absolute;
    left: -95px;
    top: 33px;
    width: 100px;
     padding: 2px 5px;
    border-radius: 5px;
    background-color: rgba(112, 115, 119, 0.6);
    color:black;
    
      transition: 0.5s all ease-in-out;
  }
  
   
     .CPU{
    
      display: block;
      position: absolute;
        background-color: rgba(112, 115, 119, 0.6);
    color:black;
   
     min-height: 30px;
      min-width: 100px;
       border-radius: 5px;
    padding: 2px 5px;
      left: -95px;
      top:2px;
      transition: 0.3s all ease-in-out;
     
         
   
    }
     &:hover{
      &::before{
        
            left: -2px;
           
            top: 0;
          

      }
      .price{
        right: -40px;
      }
      .RAM{
      
        left: -15px;
      }
      .CPU{
      
        left: -2px;
      }
      .reduse{
        right:-40px
      }
           

       }
      
        
       
    
 
       
       
         
    
}


.atbottom{
  bottom: 0;
  left: 5px;
}




</style>