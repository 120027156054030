<template>
            <div class="row  pb-3 mt-4">
                <div class="navbar-brand  col-md-12 col-xl-2  text-center text-primary  p-0 m-0 " href="#">
                    <span class="eecomerce text-danger" >Media</span><span class="store">Verkauf</span>
                </div>
                <div class="search   col-md-12 col-xl-10 p-0 m-0  ">
                        <form class="form-inline my-2 my-lg-0 col-10  ">
                          <div class="position-relative col-xl-10 col-lg-10 col-md-10 col-sm-12 pr-4 pl-0  border">
                             <input class="form-control  w-100  " style="position-absolute; top:0; left:0 !important;"
                             v-model="search" type="search" placeholder="Search" aria-label="Search">
                            <button class="btn btn-primary position-absolute " style="top:0; right:0px" >
                              <i class="fas fa-search"></i>
                            </button> 

                          </div>
                           <div class="position-relative col-xl-2 col-lg-2 col-md-2 col-sm-12 p-0 text-right   ">
                                 <router-link to="/warenkorp" id="warenkorp" 
                                 class="border   warenkorp btn btn-primary  mx-1 position-relative">
                                <i class="fas fa-shopping-cart"></i>
                                <span class="size position-absolute bg-danger" v-if="bestellungen" >{{bestellungen.length}}</span>
                                 </router-link>                        
                                 <div class="dropdown d-inline ">
                              <button 
                              :class="this.$store.getters.user ? 'border warenkorp btn btn-primary col-xs-1   dropdown-toggle':
                               'border warenkorp btn btn-secondary col-xs-1  dropdown-toggle'"
                               type="button" 
                              id="dropdownMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                               <i class="fas fa-user-tie"></i>
                              </button>
                              <div class="dropdown-menu" aria-labelledby="dropdownMenu">
                                <router-link to="/einlogen"
                                 class="dropdown-item text-primary" v-if="!this.$store.getters.user" type="button">einlogen</router-link>
                                <button class="dropdown-item text-primary" v-else  type="button" @click="ausloggen()">auslogen</button>
                                <router-link to="/userprofile" class="dropdown-item text-primary" type="button">mein Profile</router-link>
                              </div>
                                 </div>
                           </div>                           
                          
                             
                        </form>
                        <div class="  col-xl-7 my-auto text-left">                  
                                    <div class="card " v-for="product in filteredList" :key="product"> 
                                  <small> {{ product.product_category }}</small>
                                  {{ product.product_model }}                               
                              </div>
                           </div>  
                </div>           
         </div>
</template>
<script>
import db from "../components/firebaseInit";
import firebase from "firebase"
import "firebase/auth"
export default {
    name:"searchlogo",
      data(){
      return{
        cart:[],
        productslist:null,
        search:null,        
      }
    },
     created(){
             this.cart=JSON.parse(localStorage.getItem('bestellungen'))
             db.collection("products").onSnapshot((query)=>{
            this.productslist=[];
            query.forEach((doc)=>{
                this.productslist.push({
                doc_id:doc.id,
                product_Laufwerk:doc.data().product_Laufwerk,
                product_type:doc.data().product_type,
                product_Netzwerk:doc.data().product_Netzwerk,
                product_amount:doc.data().product_amount,
                product_arbeitsspeichertechnologie:doc.data().product_arbeitsspeichertechnologie,
                product_arbeitsspeicher:doc.data().product_arbeitsspeicher,
                product_color:doc.data().product_color,
                product_category:doc.data().product_category,
                product_ausstatung:doc.data().product_ausstatung,
                product_description:doc.data().product_description,
                product_festplatte:doc.data().product_festplatte,
                product_festplattetechnologie:doc.data().product_festplattetechnologie,
                product_grafikkarte:doc.data().product_grafikkarte,
                product_grafikkartehersteller:doc.data().product_grafikkartehersteller,
                product_hersteller:doc.data().product_hersteller,
                product_imageslist:doc.data().product_imageslist,
                product_kerne:doc.data().product_kerne,
                product_model:doc.data().product_model,
                product_price:doc.data().product_price,
                product_prozessor:doc.data().product_prozessor,
                product_prosessorhersteller:doc.data().product_prosessorhersteller,
                product_sound:doc.data().product_sound,
                product_zustand:doc.data().product_zustand,

                }
                   )
            })
        }).catch(err=>{console.log(err)})

        

       },
       computed:{       
         bestellungen(){return this.$store.getters.getwarenkorp; },
         beobachtet(){ return this.$store.getters.getwarenkorplength; },
          filteredList() {
            if(this.search)
            {
               return this.productslist.filter(product => {
              return product.product_model.toLowerCase().includes(this.search.toLowerCase())
               })
            }
            else{return null}  
    }        
       }       ,
       methods:{
          ausloggen(){
        firebase.auth().signOut();
         localStorage.removeItem('currentUser')
         this.$store.dispatch('setUser',null)
         this.$router.push("/")
      }
       }
}
</script>
<style lang="scss" >
 .card {
    box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
    max-width: 100%;
    margin: 12px;
    transition: 2s all ease-in-out;
 }
 .eecomerce{
   position: relative;
  
   &::before{
     content: "";
     position: absolute;
     height: 3px;
     width: 100%;
     left: 0;
     bottom: 0;
     background-color: #007bff;

   }
 }
  .store{
   position: relative;
   
   &::after{
     content: "";
     position: absolute;
     height: 3px;
     width: 100%;
     left: 0;
     bottom: 0;
     background-color: #dc3545;
     

   }
 }
 .search .warenkorp span{
   border: 1px solid black;
   padding: 0px 3px;
   border-radius: 50%;
  
   color: whitesmoke;
   top: -0%;
   right: -8%;
 }
 .search .size{
   color: red;
 }
 .search .dropdown-menu{
   transform: translate3d(0px,52px,0px) !important;
 }


</style>