<template>
   <div class="container ">
       <div class="row bestellungen">
           <table  class=" table table-hover table-dark">
            <thead>
                <tr >
                  
                  
                    <th scope="col"> Model</th>
                    <th scope="col"> Amount</th>
                     <th scope="col"> Price</th>
                     <th></th>
                </tr>
            </thead>
            <tbody>
           <tr v-for="(product,index) in bestellungen" :key="product">
               
               
                <td><router-link 
                    :to="{name:'details',params:{'productId':product.id}}"> 
                 {{product.product_model}}
                </router-link></td>
                <td>{{product.amount}}</td>
                <td v-if="product.product_reduse">                     
                    {{(product.product_price-(product.product_price*product.product_reduse/100))}} €                                
                </td> 
                <td v-else> {{product.product_price}} € </td>
                <td><i class="fas fa-trash" @click.prevent="deleteproduct(index)"></i></td>  
           </tr>
               <tr>
               
                <td  class="">Gesammt Price</td>
                <td colspan="2" >{{getsummprice()}} EUR</td>
                </tr>
            </tbody>
           </table>
       </div>
        <div class="addresse text-center">
               <h1>Liefer Addresse</h1>
                <small >{{user.strasse}}</small>
                <small >. {{user.hausnummer}}</small><br>
                <small >{{user.post}}</small><small > {{user.stadt}}</small><br>
                <router-link to="/userprofile">ändern</router-link>
       </div>
       <div class="zahlungmethode mb-3">
           <h1>Zahlung Methode</h1>
           <ul class="zahlungliste ">
                <li class="border text-left" style="width:50%; margin:auto"><input type="radio" 
                class="m-1" v-model="zahlungmethod" value="paypal" name="zahlungmethod">
                <i class="fab fa-paypal  m-1 fa-2x text-primary"></i>PayPal</li>
                <li class="border text-left" style="width:50%; margin:auto">
                <input type="radio" class="m-1" v-model="zahlungmethod" value="lastschrift" name="zahlungmethod">
                Lastschrift</li>
                <li class="border text-left  " style="width:50%; margin:auto">
                    <input type="radio" class="m-1" v-model="zahlungmethod" value="credit" name="zahlungmethod">
                    <i class="fas fa-credit-card text-danger "></i>Credit</li>
                <li class="border text-left" style="width:50%; margin:auto">
                    <input type="radio" class="m-1" v-model="zahlungmethod" value="rechnung" name="zahlungmethod">
                    <i class="fas fa-money-bill-wave"></i>Rechnung</li>
           </ul>
       </div>
       <div class=" text-center">
           <button class="btn btn-primary">Bezahlen</button>
       </div>
       
   </div>
    
</template>
<script>
import db from "../components/firebaseInit"
export default {
    name:"kasse",
    data(){
        return{
            user:null,
            bestellungen:JSON.parse(localStorage.getItem('bestellungen')),
            zahlungmethod:null

        }
    },
    props:['doc_id'],
    created(){
        if(!this.$store.getters.user)
        {
            this.$router.push("/einlogen")
        }else{
              db.collection("users").where('email','==',this.$store.getters.user.email).get().then((users)=>{
            users.forEach((user)=>{
                            this.user={}
                            this.user.id=user.id
                            this.user.email=user.data().email
                            this.user.strasse=user.data().strasse
                            this.user.hausnummer=user.data().hausnummer
                            this.user.post=user.data().post
                            this.user.stadt=user.data().stadt                   
                                                            
                             })
        })

        }
    },
    methods:{
             getsummprice(){
             let cart=JSON.parse(localStorage.getItem('bestellungen'));
            let summprices=0;
            cart.forEach(element => {
               if(element.product_reduse)
                 {
                      summprices+=(element.product_price-(element.product_price*element.product_reduse/100))*element.amount

                 }
                 else { summprices+=element.product_price*element.amount}
                 
             });
             cart.summ=summprices;
            return summprices
        },
        deleteproduct(index)
        {
            this.bestellungen.splice(index,1);
            localStorage.setItem('bestellungen',JSON.stringify(this.bestellungen));
            this.$store.dispatch('deletebestellung');
             this.getsummprice();
        }

    }
}
</script>
<style lang="scss" scoped>
.bestellungen{
    padding-top: 50px;
    padding-bottom: 50px;
}
.zahlungmethode .zahlungliste{
    margin: 0;
    padding: 0;
    list-style-type: none;
    li{
       padding: 10px 10px;

    }
}
.bestellungen table td i:hover{
    cursor: pointer;
    color: brown;

}
</style>