<template>
  <div class="about">
    
     <h1>This is Shopingcart page</h1>
</div>
   
</template>
<script>


export default {
    name: "detail",
    components:{
       
    },
     data(){
   return{
     
     msg: null
   }
},
created()
 {
  // db.collection("shopingcart").get().then((res) => this.msg=res.size)
    //        .catch(error => console.log(error))
  
 }
}
</script>